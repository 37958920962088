export const PAGE_1_ANIMATIONS = {
    ONBOARDING_1_IDX: 0,
    messageA_sec: 0.2,
    messageB_sec: 0.25,
    messageC_sec: 0.3,
    messageD_sec: 0.35,
    button_sec: 0.4,
};

export const PAGE_2_ANIMATIONS = {
    messageA_sec: 0.9,
    messageB_sec: 1.0,
};

export const ANIMATIONS = {
    5: {
        string_opacity_sec: [0.3, 0.4, 0, 0.2, 0.5, 0, 0.6, 0.1],
        message2_sec: 0.2,
        message3_sec: 0.3,
    },
    6: {
        string_opacity_sec: [0.3, 0.4, 0, 0.2, 0.5, 0, 0.6, 0.1],
        message2_sec: 0.2,
        message3_sec: 0.3,
    },
    7: {
        string_opacity_sec: [0.4, 0.5, 0.7, 0.1, 0, 0.8, 0.3, 0, 0.2, 0.6],
        message2_sec: 0.2,
        message3_sec: 0.3,
        message4_sec: 0.4,
    },
    8: {
        string_opacity_sec: [0.1, 0.3, 0, 0.2, 0.7, 0.5, 0, 0.6, 0.4],
        message2_sec: 0.2,
        message3_sec: 0.3,
        message4_sec: 0.4,
    },
};

export const PAGE_FLOW_PROGRAM_LIST_A = ['P000643144', 'P001541382', 'P001541095', 'P001542142', 'M000362138'];

export const PAGE_FLOW_PROGRAM_LIST_B = [
    'P001543077',
    'P001543232',
    'https://image.tving.com/upload/cms/caip/CAIP1500/P001479830.png',
    'M000364762',
    'P001506665',
];

export const screenCode = 'CSSD0100';
export const osCode = 'CSOD0900';
export const pocCode = 'POCD0200';
export const TVING_DEFAULT_IMG = 'tving-default-img';
export const TVING_DEFAULT_IMG_MOBILE_URL = '/img/tving-new-logo-default-v.png';
export const TVING_DEFAULT_IMG_PC_URL = '/img/tving-new-logo-default-h.png';
