import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { colors } from 'src/styles/colors';
import { space } from 'src/styles/space';
import { device, mq } from 'src/styles/device';

export const CssLayoutPadding = css`
    /* padding: 0 1.333rem;
    @media ${device.tablet} {
        padding: 0 3.888rem;
    } */
    ${mq({
        paddingLeft: space.containerSidePadding,
        paddingRight: space.containerSidePadding,
    })}
`;
export const StyledContainer = styled.section`
    ${CssLayoutPadding};
    position: relative;
`;
