/** @jsxImportSource @emotion/react */
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { palette } from '@styles/colors';
import { device, mq } from '@styles/device';
import { CssLayoutPadding } from '@styles/layout/container';
import zIndexes from '@styles/zIndexes';
import { imgOrigin } from '@utils/apiConfig';

const page1ImageIn = keyframes`
    0% {
        opacity: 0;
        transform: translate3d(0, 120px, 0);
    },

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
`;

export const page1In = keyframes`
    0% {
        opacity: 0;
        transform: translateY(60px);
    },

    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const page1Out = keyframes`
    0% {
        opacity: 0;
        transform: translateY(0px);
    },

    100% {
        opacity: 1;
        transform: translateY(60px);
    }
`;

export const FlowSlideAnimation = css`
    @keyframes firstFlow {
        0% {
            transform: translate(0);
        }
        100% {
            transform: translate(-100%);
        }
    }
    @keyframes secondFlow {
        0% {
            transform: translate(100%);
        }
        100% {
            transform: translate(0);
        }
    }
`;

export const StyledWrapper = styled.div`
    position: relative;
`;

export const StyledScene1Wrapper = styled.section`
    width: 100%;
    height: auto;
    position: relative;
    max-height: 50rem;

    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, top;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 7rem;

    animation: ${page1ImageIn} 1.2s 0.1s forwards;

    .content-wrap {
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: auto 100%;
            @media ${`${device.mobile}`} {
                width: 100%;
                max-width: 288px;
                background-image: linear-gradient(
                    rgba(0, 0, 0, 1) 10%,
                    rgba(0, 0, 0, 0.5) 30%,
                    rgba(0, 0, 0, 0.1) 40%,
                    rgba(0, 0, 0, 0) 80%,
                    rgba(0, 0, 0, 0.2) 85%,
                    rgba(0, 0, 0, 0.3) 90%,
                    rgba(0, 0, 0, 0.8) 95%,
                    rgba(0, 0, 0, 1) 100%
                );
            }
        }
    }
`;

type StyledScene0WrapperProps = {
    vertical?: string;
    horizontal?: string;
};

export const StyledScene0Wrapper = styled.section<StyledScene0WrapperProps>`
    width: 100%;
    height: 85vh;
    max-height: 50rem;
    background-image: ${({ vertical }) =>
        vertical
            ? `url('/img/moweb-img-gradation-250123.png'), url(${imgOrigin}${vertical}/dims/resize/F_webp,720)`
            : `url('/img/moweb-img-gradation-250123.png'), url('/img/moweb-img@2x.jpg')`};

    background-size: cover;
    background-repeat: no-repeat, no-repeat;
    background-position: bottom, top;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3.667rem;

    .is-laptop & {
        margin-top: 0;
    }
    @media (min-width: 500px) {
        height: 95vh;
        max-height: 68vw;
        background-image: ${({ horizontal }) =>
            horizontal
                ? `url('/img/pc-img-gradation-250123.png'), url(${imgOrigin}${horizontal}/dims/resize/F_webp,2048)`
                : `url('/img/pc-img-gradation-250123.png'), url('/img/pc-img@2x.jpg')`};
        margin-top: 0;
    }

    @media ${device.laptop} {
        max-height: 80vh;
    }

    @media (min-width: 2400px) {
        max-height: 77vh;
    }

    animation: ${page1ImageIn} 1.2s 0.1s forwards;
`;

export const StyledScene0MessageWrapper = styled.div`
    text-align: center;
    margin-top: -1.5rem;

    @media (orientation: landscape) and (max-width: 900px) {
        margin-top: 4rem;
    }

    @media ${device.laptop} {
        margin-top: 4.1rem;
    }
`;

type StyledScene0MessageProps = {
    isActive: boolean;
    sec: number;
};

export const StyledScene0Message = styled.p<StyledScene0MessageProps>`
    color: ${palette.white};
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.33;
    opacity: 0;

    ${({ isActive, sec }) =>
        isActive &&
        css`
            animation: ${page1In} 1.5s ${sec}s forwards;
        `}

    @media ${device.laptop} {
        font-size: 3rem;
        line-height: 4.25rem;
    }
`;

type StyledScene0ButtonProps = {
    isActive: boolean;
    sec: number;
    isTivingLogo?: boolean;
};

export const StyledScene0Button = styled.button<StyledScene0ButtonProps>`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: 4.083rem;
    padding: ${({ isTivingLogo }) => (isTivingLogo ? '0 2.6rem 0 4.1rem' : '0 3.35rem 0 3.35rem')};
    font-size: 1.333rem;
    margin: 2.8rem auto 0;
    font-weight: bold;
    color: ${palette.white};
    background-color: ${palette.red600};
    border-radius: 4px;
    opacity: 0;
    min-width: 22.5rem;

    ${({ isActive, sec }) =>
        isActive &&
        css`
            animation: ${page1In} 1.5s ${sec}s forwards;
        `}

    @media ${device.laptop} {
        font-size: 1.5rem;
        padding: ${({ isTivingLogo }) => (isTivingLogo ? '0 4.8rem 0 6.1rem' : '0 5.44rem')};
        height: 4.667rem;
        margin: 3.333rem auto 0;
        min-width: 28rem;
    }

    ${({ isTivingLogo }) =>
        isTivingLogo &&
        css`
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url('/img/img_tving-symbol.svg') no-repeat left top;
                background-size: contain;
            }
        `}
`;

type StyledScene0SubMessageProps = {
    isActive: boolean;
    sec: number;
};

export const StyledScene0SubMessage = styled.p<StyledScene0SubMessageProps>`
    margin-top: 0.667rem;
    color: ${palette.inverseGrey600};
    font-size: 1.167rem;
    line-height: 1.29;
    opacity: 0;

    ${({ isActive, sec }) =>
        isActive &&
        css`
            animation: ${page1In} 1.5s ${sec}s forwards;
        `}

    @media ${device.laptop} {
        margin-top: 1rem;
        font-size: 1.667rem;
    }
`;
export const StyledScrollSceneWrapper = styled.div`
    display: none;
    z-index: auto;
    float: none;
    flex-shrink: 1;
    display: block;
    margin: 0px;
    inset: 0px;
    position: relative;
    overflow: visible;
    box-sizing: border-box;
    width: 1540px;
    height: 900px;
    padding: 0px 0px 300px;
`;
export const StyledScene2Wrapper = styled.section`
    padding-top: 8.333rem;
    width: 100%;
    position: relative;
    background-color: #000;

    @media ${device.laptop} {
        padding-top: 10rem;
    }
`;

type StyledScene3WrapperProps = {
    width: number;
};

export const StyledScene3Wrapper = styled.section<StyledScene3WrapperProps>`
    width: 100%;
    margin: 3.833rem 0 8.333rem;
    position: relative;
    background-color: #000;

    &.in {
        opacity: 1;
        transform: translateY(0rem);
    }

    > div {
        margin: 0 auto;
        ${({ width }) =>
            css`
                max-width: ${width}px;
            `}
    }

    @media ${device.tablet} {
        margin: 1.667rem 0 8.333rem;
    }

    @media ${device.laptop} {
        margin: 2.5rem 0 10rem;
    }
`;

type StyledMainMessageProps = {
    isFirstLine?: boolean;
};

export const StyledMainMessage = styled.p<StyledMainMessageProps>`
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    color: ${palette.inverseGrey800};
    margin-top: 0.5rem;

    @media ${device.laptop} {
        font-size: 1.833rem;
        font-weight: 500;
        ${({ isFirstLine }) =>
            isFirstLine &&
            css`
                margin-top: 1.667rem;
            `}
    }
`;

export const StyledScene4Wrapper = styled.section`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #000;
    overflow: hidden;
    padding: 0 1.737rem;

    @media ${device.tablet} {
        padding: 0 17.4rem;
    }
`;

export const StyledScene5Wrapper = styled.section`
    width: 100%;
    height: 100%;
    padding-top: 10rem;
    position: relative;
    background-color: #000;
    overflow: hidden;
    padding: 9.167rem 1.737rem 0;

    @media ${device.tablet} {
        padding: 8.333rem 17.4rem 0;
    }

    @media ${device.laptop} {
        padding: 8.333rem 0 0;
    }
`;

export const StyledNo1Icon = styled.img`
    width: 10.899rem;
    height: 4.23rem;
    object-fit: contain;

    @media ${device.tablet} {
        width: 14.083rem;
        height: 5.466rem;
    }

    @media ${device.laptop} {
        width: 16.349rem;
        height: 6.345rem;
    }
`;

export const StyledPage8Message1 = styled.h2`
    font-size: 2rem;
    font-weight: bold;
    line-height: 2.5rem;

    @media ${device.tablet} {
        font-size: 2.5rem;
        margin-top: 0.2rem;
    }

    @media ${device.laptop} {
        font-size: 3rem;
        line-height: 1.06;
        margin-top: 0.5rem;
    }
`;

export const StyledScene6Wrapper = styled.div`
    text-align: center;
    position: relative;
    background-color: #000;
    overflow: hidden;
    margin-bottom: 11.667rem;

    &.in {
        opacity: 1;
        transform: translateY(0rem);
        & > img {
            opacity: 1;
            transition: opacity 1.7s ease-out, transform 1.7s ease-out;
            transform: translateY(0rem);
        }

        & > h2 {
            opacity: 1;
            transition: opacity 1.7s ease-out, transform 1.7s ease-out;
            transform: translateY(0rem);
        }
    }

    @media ${device.laptop} {
        margin-bottom: 16.667rem;
    }
`;

export const StyledScene7Wrapper = styled.div`
    position: relative;
    background-color: #000;

    ${CssLayoutPadding}
`;

export const StyledTextWrapper = styled.div`
    opacity: 0;
    white-space: normal;
    transition: opacity 0.5s ease-out, transform 0.5s ease-out;
    transform: translateY(0rem);

    p {
        opacity: 0;
        transition: opacity 0.3s ease-out, transform 0.3s ease-out;
        transform: translateY(3rem);
    }

    .in & {
        opacity: 1;
        transform: translateY(0rem);
        p {
            opacity: 1;
            transition: opacity 0.7s ease-out, transform 0.7s ease-out;
            transform: translateY(0rem);
            &:nth-of-type(1) {
                transition-delay: 0.2s;
            }
            &:nth-of-type(2) {
                transition-delay: 0.5s;
            }
            &:nth-of-type(3) {
                transition-delay: 0.7s;
            }
            &:nth-of-type(4) {
                transition-delay: 0.8s;
            }
            &:nth-of-type(5) {
                transition-delay: 0.9s;
            }
        }
    }
`;
export const StyledTitle = styled.p`
    font-size: 2.35rem;
    font-weight: bold;
    line-height: 1.35;
    text-align: center;
    color: ${palette.white};

    @media ${device.laptop} {
        font-size: 3.25rem;
        line-height: 1;
    }
`;

export const StyledSubMessage = styled.p`
    font-size: 1.167rem;
    font-weight: normal;
    text-align: center;
    color: ${palette.inverseGrey600};
    margin-top: 0.833rem;

    @media ${device.laptop} {
        font-size: 1.417rem;
        opacity: 0.64;
        margin-top: 0.667rem;
    }
`;

export const StyledFlowSceneWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    overflow: hidden;
    ${FlowSlideAnimation}
`;

export const StyledPage6ImgWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;

export const StyledPage6ImgWrapper = styled.li`
    perspective: 300px;
    margin-left: 1.4rem;
`;

export const StyledPage6Img = styled.img`
    width: 15.8rem;
    height: 22.8rem;
    object-fit: contain;
    transform: rotate3d(0, 1, 0, -13deg);
    -webkit-transform: rotate3d(0, 1, 0, -13deg);
`;

type StyledFirstImgListWrapperProps = {
    isLeave: boolean;
};

export const StyledFirstImgListWrapper = styled.ul<StyledFirstImgListWrapperProps>`
    position: absolute;
    display: flex;
    animation-duration: 100s;

    &:first-of-type {
        animation: firstFlow 20s linear infinite;

        ${({ isLeave }) =>
            isLeave
                ? css`
                      animation-play-state: paused;
                  `
                : css`
                      animation-play-state: running;
                  `}
    }
    &:last-of-type {
        animation: secondFlow 20s linear infinite;
        transform: translate(100%);

        ${({ isLeave }) =>
            isLeave
                ? css`
                      animation-play-state: paused;
                  `
                : css`
                      animation-play-state: running;
                  `}
    }
`;

type StyledSecondImgListWrapperProps = {
    isLeave: boolean;
};

export const StyledSecondImgListWrapper = styled.ul<StyledSecondImgListWrapperProps>`
    position: absolute;
    display: flex;
    animation-duration: 100s;

    &:first-of-type {
        animation: firstFlow 15s linear infinite;

        ${({ isLeave }) =>
            isLeave
                ? css`
                      animation-play-state: paused;
                  `
                : css`
                      animation-play-state: running;
                  `}
    }
    &:last-of-type {
        animation: secondFlow 1s linear infinite;
        transform: translate(100%);

        ${({ isLeave }) =>
            isLeave
                ? css`
                      animation-play-state: paused;
                  `
                : css`
                      animation-play-state: running;
                  `}
    }
`;

export const StyledVideo4 = styled.video`
    display: block;
    width: 100%;
    margin: 3.833rem auto 0rem;
    pointer-events: none;

    @media ${device.tablet} {
        width: 39.1vw;
        height: 44.6vw;
        margin: 3.5rem auto 0;
        max-width: 39.1rem;
        max-height: 44.6rem;
    }

    @media ${device.laptop} {
        margin: 3.833rem auto 0;
    }
`;

export const StyledVideo5 = styled.video`
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: contain;
    object-position: center top;
`;

export const StyledImage5 = styled.img`
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    object-fit: contain;
    object-position: center top;
`;

type StyledPage8ButtonProps = {
    isTivingLogo?: boolean;
};

export const StyledPage8Button = styled.button<StyledPage8ButtonProps>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 22.5rem;
    height: 4.083rem;
    padding: ${({ isTivingLogo }) => (isTivingLogo ? '0 2.6rem 0 4.1rem' : '0 3.35rem 0 3.35rem')};
    margin: 3rem auto 0;
    background-color: ${palette.red600};
    color: ${palette.white};
    font-size: 1.333rem;
    font-weight: bold;
    border-radius: 4px;

    @media ${device.tablet} {
        height: 4.083rem;
        font-size: 1.25rem;
        margin: 2.417rem auto 0;
    }

    @media ${device.laptop} {
        padding: ${({ isTivingLogo }) => (isTivingLogo ? '0 4.8rem 0 6.1rem' : '0 5.44rem')};
        height: 4.667rem;
        font-size: 1.5rem;
        min-width: 28rem;
    }

    ${({ isTivingLogo }) =>
        isTivingLogo &&
        css`
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url('/img/img_tving-symbol.svg') no-repeat left top;
                background-size: contain;
            }
        `}
`;

export const StyledPage7Message = styled.p`
    text-align: center;
    font-size: 2.833rem;
    font-weight: bold;
    color: ${palette.white};
    padding-bottom: 3.333rem;

    @media ${device.laptop} {
        font-size: 3.25rem;
    }
`;

export const StyledFAQWrapper = styled.ul``;
export const StyledFAQ = styled.li`
    &:nth-of-type(n + 2) {
        border-top: 1px solid ${palette.inverseGrey50};
    }
`;

export const StyledLabel = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 5.833rem;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #cecece;
    font-size: 1.333rem;
    line-height: normal;
    cursor: pointer;

    &::after {
        content: '';
        display: inline-block;
        min-width: 1.083rem;
        min-height: 1.5rem;
        background-image: url('/img/icon-drop-down.svg');
        background-size: 100%;
        background-repeat: no-repeat;
        object-fit: contain;

        @media ${device.laptop} {
            min-width: 1.667rem;
            min-height: 2.333rem;
        }
    }

    @media ${device.laptop} {
        padding: 2.167rem 0;
        font-size: 1.667rem;
    }
`;

export const StyledFAQContentWrapper = styled.div`
    max-height: 0px;
    overflow: hidden;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
`;

export const StyledFAQContent = styled.div`
    padding-bottom: 2rem;
    font-size: 1.25rem;
    color: ${palette.inverseGrey600};
`;

export const StyledInput = styled.input`
    position: absolute;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    opacity: 0;

    :checked + label::after {
        background-image: url('/img/icon-drop-up.svg');
    }

    :checked + label {
        color: ${palette.white};
        font-weight: bold;

        @media ${device.laptop} {
            padding: 0;
        }
    }

    :checked + label + ${StyledFAQContentWrapper} {
        max-height: 300rem;
    }
`;

export const StyledRegisterCouponWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 5.333rem;
`;

export const StyledRegisterCoupon = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${palette.black};
    background-color: ${palette.inverseGrey800};
    width: 100%;
    height: 4.9rem;
    font-weight: bold;
    font-size: 1.333rem;
    border-radius: 4px;

    @media ${device.tablet} {
        width: 28.667rem;
        height: 4.667rem;
        font-size: 1.5rem;
    }
`;

export const StyledQuickVodImg = styled.img`
    position: absolute;
    width: 94.4vw;
    height: 25.6vw;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;

    &.show {
        animation: ${page1In} 0.4s 0.7s forwards;
    }

    @media ${device.tablet} {
        width: 44.1vw;
        height: 12.3vw;
        bottom: 0;
        max-width: 44.417rem;
        max-height: 12.333rem;
    }
`;

export const StyledFaqTitle = styled.span`
    max-width: 26.833rem;

    @media ${device.tablet} {
        max-width: initial;
    }
`;

export const StyledAppIntroContent = styled.div`
    color: ${palette.white};
    margin-left: 0.8rem;
`;

type StyledAppIntroWrapperProps = {
    isActive: boolean;
    isScroll: boolean;
};

export const StyledAppIntroWrapper = styled.div<StyledAppIntroWrapperProps>`
    z-index: ${zIndexes.onboarding};
    position: ${({ isActive }) => (isActive ? 'absolute' : 'static')};
    top: 0;
    left: 0;
    width: 100%;
    height: 3.667rem;
    display: flex;
    align-items: center;
    background-color: ${palette.black};
    font-size: 1rem;
    ${CssLayoutPadding}
    ${({ isScroll }) =>
        !isScroll &&
        css`
            border-bottom: 2px solid ${palette.inverseGrey50};
        `}
`;

export const StyledTIcon = styled.img`
    width: 1.9rem;
    height: 1.9rem;
`;
